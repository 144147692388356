import * as React from "react";
import { MessageIDS, t } from "../../i18n/util";
import { AccountType } from "../../network/APITypes";
import { Option } from "../../types";

type AccountTypesToMessageId = {
    [key in AccountType]: MessageIDS;
};

export const AccountTypes: AccountTypesToMessageId = {
    "b2b-customer": "accountType.b2bCustomer",
    employee: "accountType.employee",
    supplier: "accountType.supplier",
};

export const accountTypeOptions: Option[] = [
    { label: t("accountType.b2bCustomer"), value: "b2b-customer" },
    { label: t("accountType.employee"), value: "employee" },
    { label: t("accountType.supplier"), value: "supplier" },
];
