import { Field } from "formik";
import { GetOCIOverridesResponse } from "../../../network/APITypes";
import { CustomAutocomplete } from "../../ui/CustomAutocomplete";
import { t } from "../../../i18n/util";
import { MAX_OCI_CONSTANT_LENGTH } from "../../../config";

const overrideValues = [
    "BaseArticle.Name",
    "BaseArticle.Matchcode",
    "Quantity",
    "BaseArticle.UnitOfSale",
    "BaseArticle.BasePrice.Amount",
    "BaseArticle.BasePrice.Unit",
    "BaseArticle.Manufacturer.ShortName",
    "BaseArticle.Manufacturer.Name",
    "BaseArticle.ManufacturerArticleNumber",
    "BaseArticle.ImageURL",
    "BaseArticle.EClassNumber",
    "OrderPosition.DeliveryTimeDays",
    "Prices.NetPriceWithSurcharges", // price
    "Prices.NetPrice", // price
];

export const isOciOverrideValue = (value?: string) => {
    return value ? overrideValues.includes(value.trim()) : false;
};

// Use this to convert OCI key to Formik compatible names. Formik does not like [, ], (, )
export const ociToFormikName = (ociKey: string) => {
    return ociKey.replace("[", "+").replace("]", "*");
};

export const CustomerDetailsOCIFields = ({ overrides }: { overrides: GetOCIOverridesResponse }) => {
    return (
        <>
            {overrides.ociOverrides
                .sort((a, b) => {
                    return a.field.localeCompare(b.field);
                })
                .map((field) => {
                    // Default value have no ID, but an override
                    const isDefault = !field.id && (field.constantValue || field.valueKey);
                    return (
                        <Field
                            key={field.field}
                            component={CustomAutocomplete}
                            label={field.field + (isDefault ? t("oci.default") : "")}
                            name={ociToFormikName(field.field)}
                            type={"input"}
                            maxLength={MAX_OCI_CONSTANT_LENGTH}
                            options={overrideValues.map((value) => ({ value, label: value }))}
                            useFreeSoloValue
                        />
                    );
                })}
        </>
    );
};
