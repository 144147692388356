import { default as dayjs } from "dayjs";
import { defaultCountries, parseCountry } from "react-international-phone";
import { VALID_PHONE_NUMBER_LENGTH } from "../../config";
import { UserRequest } from "../../network/APITypes";
import { APIClientStatusCodeError } from "../../network/NetworkStapler";

export function getFormattedDate(date?: Date, format = "DD.MM.YYYY") {
    if (!date) {
        return "";
    }

    return dayjs(date).format(format).toString();
}

export const passwordRegex = /^(?=.*[A-Za-z])(?=.*[\d\W]).{8,}$/;

export const isErrorOfType = (error: unknown, type: string) => {
    return error instanceof APIClientStatusCodeError && error.response.type === type;
};

export const userHasPermission = (user: UserRequest, serviceIdentifier: string) => {
    return user.permissionServices?.some((service) => service.identifier === serviceIdentifier && service.isGranted);
};

export const hasUserManagement = (user?: UserRequest | null) => {
    return !!user && userHasPermission(user, "users:management");
};

export const hasVerifiedEmail = (user?: UserRequest | null) => {
    return user?.appUserProfile && user.appUserProfile.email && !user.appUserProfile.emailConfirmationPending;
};

// Removes spaces, brackets, dashes from phone number
export const normalizePhoneNumber = (phoneNumber?: string) => {
    return phoneNumber?.replace(/[- )(]/g, "");
};

export const isValidPhoneNumber = (phoneNumber?: string) => {
    return !!phoneNumber && (normalizePhoneNumber(phoneNumber)?.length ?? 0) >= VALID_PHONE_NUMBER_LENGTH;
};

export const isEmptyOrOnlyCountryDialCode = (phoneNumber?: string, dialCode = "") => {
    if (phoneNumber && dialCode) {
        // Dial code provided -> direct check
        return phoneNumber.trim() === `+${dialCode}`;
    }

    // No dial code provided -> check against all countries
    return (
        !phoneNumber ||
        defaultCountries.some((c) => {
            const country = parseCountry(c);
            return phoneNumber.trim() === `+${country.dialCode}`;
        })
    );
};
