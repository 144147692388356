import { Divider, Table, TableBody, TableRow } from "@mui/material";
import { styled } from "@mui/system";
import { compact, uniqBy } from "lodash";
import { observer } from "mobx-react";
import { SEARCH_FIELD_MAX_WIDTH } from "../../../config";
import { useCustomerDetailsForm } from "../../../hooks/useCustomerDetailsForm";
import { useCustomers } from "../../../hooks/useCustomers";
import { useDistributors } from "../../../hooks/useDistributors";
import { useMultiSelectionFilter } from "../../../hooks/useMultiSelectionFilter";
import { useOciFilter } from "../../../hooks/useOciFilter";
import { usePagination } from "../../../hooks/usePagination";
import { t } from "../../../i18n/util";
import { EmptyState } from "../../ui/EmptyState";
import { CmsTableCell, CmsTableContainer, SiteContainer, TableLabel } from "../../ui/Primitives";
import { SearchField } from "../../ui/SearchField";
import { TableHeader, TableHeaderConfig } from "../../ui/TableHeader";
import { Filters } from "../../ui/filters/Filters";
import { Icon } from "../../util/Icon";

const ActionIcon = styled("div")({
    display: "flex",
    justifyContent: "center",
    width: 90,
});

export const OciManagementSite = observer(() => {
    const pagination = usePagination();

    const distributors = useDistributors();

    const customerDetailsForm = useCustomerDetailsForm({
        distributorsHook: distributors,
        onSubmit(changed) {
            if (changed) {
                reloadCustomers();
            }
        },
    });

    const distributorFilter = useMultiSelectionFilter({
        name: "distributors",
        label: t("filter.label.distributor"),
        options:
            uniqBy(distributors.distributors, (distributor) => distributor.externalVkorgID).map((distributor) => ({
                value: distributor.externalVkorgID,
                label: distributor.shortName,
            })) ?? [],
    });

    const standardOciFilter = useOciFilter();

    const filterResetComponents = compact([distributorFilter.resetComponent, standardOciFilter.resetComponent]);

    let defaultOCI = undefined;
    if (standardOciFilter.values.length === 1) {
        defaultOCI = standardOciFilter.values[0] === "standard";
    }

    const { customers, isLoading, reloadCustomers } = useCustomers({
        limit: pagination.pageSize,
        offset: pagination.offset,
        search: pagination.search,
        distributors: distributors.getIDsByExternalVkorgIDs(distributorFilter.values),
        defaultOCI,
    });

    const headerFields: TableHeaderConfig[] = [
        { column: "customerNumber", label: "table.label.customerNumber", style: { width: 0 } },
        { column: "vkoShortName", label: "table.label.vko" },
        { column: "name", label: "table.label.companyName" },
        { column: "hasDefaultOCISettings", label: "table.label.standardOci", style: { width: 0 } },
        { column: "actions", style: { width: 0 } },
    ];

    const tableBody = (
        <TableBody>
            {customers?.customers?.map((customer, index) => {
                return (
                    <TableRow key={customer.id}>
                        {headerFields.map(({ column }, index) => {
                            let label = customer[column];

                            if (column === "customerNumber") {
                                label = customer?.externalId;
                            }

                            if (column === "vkoShortName") {
                                label = distributors.getDistributorShortName(customer?.distributorId) ?? "-";
                            }

                            if (column === "hasDefaultOCISettings") {
                                label = t(customer?.hasDefaultOCISettings ? "common.yes" : "common.no");
                            }

                            if (column === "actions") {
                                return (
                                    <CmsTableCell key={column}>
                                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <ActionIcon>
                                                <Icon
                                                    name="edit"
                                                    size={32}
                                                    onClick={() => customerDetailsForm.open(customer.id)}
                                                />
                                            </ActionIcon>
                                        </div>
                                    </CmsTableCell>
                                );
                            }

                            return (
                                <CmsTableCell
                                    key={column}
                                    style={{
                                        maxWidth: column !== "status" ? 100 : undefined,
                                    }}
                                >
                                    <TableLabel style={{ maxWidth: "100%" }}>{label}</TableLabel>
                                </CmsTableCell>
                            );
                        })}
                    </TableRow>
                );
            })}
        </TableBody>
    );

    const isEmpty = customers?.customers?.length === 0;

    return (
        <SiteContainer>
            <h1>{t("common.oci")}</h1>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: 52 }}>
                <Filters>
                    {distributorFilter.component}
                    {standardOciFilter.component}
                </Filters>
                <div style={{ maxWidth: SEARCH_FIELD_MAX_WIDTH, width: "100%" }}>
                    <SearchField
                        placeholder={t("screen.users.search.placeholder")}
                        onChange={pagination.onChangeSearch}
                        value={pagination.search}
                        style={{ width: "100%" }}
                        readOnly={isLoading}
                    />
                </div>
            </div>
            <Divider style={{ marginTop: 16 }} />
            {filterResetComponents.length > 0 && <Filters>{filterResetComponents}</Filters>}
            {isEmpty && <EmptyState iconName="mail" title={t("screen.customers.noResult.title")} />}
            {!isEmpty && (
                <>
                    <div style={{ marginTop: 48 }}>{pagination.component(customers?.total ?? 0)}</div>

                    <CmsTableContainer>
                        <Table>
                            <TableHeader
                                headerFields={headerFields}
                                orderBy={pagination.orderBy}
                                orderDir={pagination.orderDir}
                                onChangeOrder={pagination.onChangeOrder}
                            />
                            {tableBody}
                        </Table>
                    </CmsTableContainer>
                </>
            )}
            {customerDetailsForm.component}
        </SiteContainer>
    );
});
