import { Button, Dialog, Tooltip } from "@mui/material";
import * as React from "react";
import { DialogHeader } from "../components/ui/DialogHeader";
import { EmptyState } from "../components/ui/EmptyState";
import { GenericItemSearch } from "../components/ui/GenericItemSearch";
import { GrantedPermissionServicesList } from "../components/ui/GrantedPermissionServicesList";
import { PermissionsContainer, TwoColumnLayoutContainer } from "../components/ui/Primitives";
import { Colors } from "../components/util/Colors";
import { t } from "../i18n/util";
import { Distributor, UserRequest } from "../network/APITypes";
import { useUserPermissions } from "./useUserPermissions";
import { useUsers } from "./useUsers";
import { useDistributors } from "./useDistributors";
import { hasUserManagement, hasVerifiedEmail } from "../components/util/Helpers";

const UserItem = ({
    user,
    distributors,
    style,
}: {
    user: UserRequest;
    distributors: Distributor[] | null;
    style?: React.CSSProperties;
}) => {

    return (
        <>
            <div className="body1Bold" style={{ color: Colors.HEADINGS_DARK, ...style }}>
                {user.lastName} {user.firstName}
            </div>
            <div className="body1" style={{ fontSize: 14, marginTop: 8, ...style }}>
                {user.email}
            </div>
            <div className="body1" style={{ fontSize: 14, marginTop: 4, ...style }}>
                {user.corporatePosition?.role.name}
                {user.corporatePosition?.role.name && " – "}
                {distributors?.find(distributor =>distributor.id === user.distributorID)?.shortName}
            </div>
            {user.customer?.externalId && (
                <div className="body1" style={{ fontSize: 14, marginTop: 4, ...style }}>
                    {t("table.label.customerNumber")}: {user.customer?.externalId}
                </div>
            )}
        </>
    );
};

type CopyPermissionServicesDialogProps = {
    onSubmit: (permissionServiceIds: string[]) => Promise<void>;
    currentUser?: UserRequest | null;
};

export const useCopyPermissionServicesDialog = ({ onSubmit, currentUser }: CopyPermissionServicesDialogProps) => {
    const [open, setOpen] = React.useState(false);
    const [search, setSearch] = React.useState<string>();
    const [selectedUser, setSelectedUser] = React.useState<UserRequest>();
    const { users, resetUsers } = useUsers({ status: ["active"], limit: 20, search }, open);
    const userPermissions = useUserPermissions({ id: selectedUser?.id, options: { limit: 0 } });

    const { distributors } = useDistributors();

    React.useEffect(() => {
        setSelectedUser(undefined);
    }, [search]);

    React.useEffect(() => {
        if (!open) {
            resetUsers();
        }

        setSearch(undefined);
        setSelectedUser(undefined);
    }, [open, resetUsers]);

    const handleClose = () => {
        setOpen(false);
    };

    const additionalPermissionServices = userPermissions.permissions?.filter(
        (permissionService) =>
            permissionService.isGranted &&
            permissionService.isExplicitlyGranted &&
            permissionService.grantedBy?.length === 0,
    );

    const hasAdditionalPermissionServices = additionalPermissionServices && additionalPermissionServices.length > 0;

    const handleSubmit = async () => {
        if (selectedUser && additionalPermissionServices) {
            await onSubmit(additionalPermissionServices.map((permissionService) => permissionService.identifier));
            handleClose();
        }
    };

    const selectedHasUserManagementPermission = hasUserManagement(selectedUser);
    const currentUserHasEmail = hasVerifiedEmail(currentUser);
    const invalidUserManagementCopy = !currentUserHasEmail && selectedHasUserManagementPermission;

    const component = (
        <Dialog fullScreen open={open} onClose={handleClose} PaperProps={{ style: { padding: 32 } }}>
            <DialogHeader title={t("user.action.copyPermissionServicesDialog.title")} onClose={handleClose} />
            <TwoColumnLayoutContainer style={{ marginTop: 32 }}>
                <GenericItemSearch
                    title={t("user.action.copyPermissionServicesDialog.users.title")}
                    placeholder={t("user.action.copyPermissionServicesDialog.users.search.placeholder")}
                    items={users?.userRequests ?? []}
                    onChangeSearch={setSearch}
                    onClickItem={setSelectedUser}
                    itemRenderer={(user, itemStyle) => (
                        <UserItem key={user.id} user={user} distributors={distributors} style={itemStyle} />
                    )}
                />
                <PermissionsContainer>
                    {selectedUser && hasAdditionalPermissionServices && (
                        <>
                            <div className="body1Bold" style={{ color: Colors.BODY_2 }}>
                                {t("user.action.copyPermissionServicesDialog.permissions.title")}
                            </div>
                            <GrantedPermissionServicesList
                                permissionServices={additionalPermissionServices}
                                diffingPermissionServices={currentUser?.permissionServices}
                                style={{ marginTop: 24 }}
                            />
                        </>
                    )}
                    {selectedUser && !hasAdditionalPermissionServices && (
                        <EmptyState
                            iconName="sadFace"
                            title={t("user.action.copyPermissionServicesDialog.emptyScreen.noPermissions.title")}
                            description={t(
                                "user.action.copyPermissionServicesDialog.emptyScreen.noPermissions.description",
                            )}
                        />
                    )}
                    {!selectedUser && (
                        <div style={{ display: "flex", flexGrow: 1, justifyContent: "center" }}>
                            <EmptyState
                                iconName="search"
                                title={t("user.action.copyPermissionServicesDialog.emptyScreen.noSelectedUser.title")}
                                description={t(
                                    "user.action.copyPermissionServicesDialog.emptyScreen.noSelectedUser.description",
                                )}
                            />
                        </div>
                    )}
                </PermissionsContainer>
            </TwoColumnLayoutContainer>
            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 32 }}>
                <Tooltip title={invalidUserManagementCopy ? t("screen.userDetails.emailNotConfirmed") : undefined}>
                    <div>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleSubmit}
                            disabled={!selectedUser || !hasAdditionalPermissionServices || invalidUserManagementCopy}
                        >
                            {t("user.action.copyPermissionServicesDialog.submit.button.text")}
                        </Button>
                    </div>
                </Tooltip>
            </div>
        </Dialog>
    );

    return {
        open() {
            setOpen(true);
        },
        component,
    };
};
