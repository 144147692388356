import * as React from "react";
import { AddUserForm } from "../components/forms/addUser/AddUserForm";
import { CustomDrawer } from "../components/ui/CustomDrawer";

export const useAddUserForm = ({ onSubmit }: { onSubmit?: () => void | Promise<void> }) => {
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const component = open ? (
        <CustomDrawer open={open} onClose={handleClose} style={{ padding: "32px 16px" }}>
            <AddUserForm onClose={handleClose} onSubmit={onSubmit} />
        </CustomDrawer>
    ) : null;

    return {
        open() {
            setOpen(true);
        },

        component,
    };
};
