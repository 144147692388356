import { LoadingButton } from "@mui/lab";
import { Button, Step, StepContent, StepLabel, Stepper, Tooltip } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import { cloneDeep } from "lodash";
import { observer } from "mobx-react";
import * as React from "react";
import * as Yup from "yup";
import { useConfirmationDialog } from "../../../hooks/useConfirmationDialog";
import { useCustomerStatus } from "../../../hooks/useCustomerStatus";
import { useInvitationTypeSelection } from "../../../hooks/useInvitationTypeSelection";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import {
    BaseCustomer,
    Distributor,
    PostCreateUserInvitePayloadInvitationTypeEnum,
    PostUserRequestsPayloadV2,
} from "../../../network/APITypes";
import { generalStore } from "../../../stores/GeneralStore";
import { DialogStepper } from "../../ui/DialogStepper";
import { FieldError } from "../../ui/FieldError";
import { LinkButton } from "../../ui/LinkButton";
import { getUserDialogConfiguration } from "../../util/Dialogs";
import { getFormattedDate, isErrorOfType, isValidPhoneNumber, normalizePhoneNumber } from "../../util/Helpers";
import { Icon } from "../../util/Icon";
import { userValidationSchema } from "../editUser/EditUserForm";
import { UserDetailsFields } from "../shared/UserDetailsFields";
import { CustomerNumberSelection } from "./CustomerNumberSelection";
import { VkoSelection } from "./VkoSelection";
import { useDistributors } from "../../../hooks/useDistributors";
import { DialogHeader } from "../../ui/DialogHeader";
import { Colors } from "../../util/Colors";

type FormikPostUserRequestsPayload = Omit<PostUserRequestsPayloadV2, "accountType"> & { accountType?: string };

type MultiFormStepConfig = {
    name: string;
    title: string;
    step: string;
    validationSchema?: any;
    content?: React.ReactNode;
    autoOverflow?: boolean;
};

const InviteButton = (props: {
    children: string;
    values: FormikPostUserRequestsPayload;
    onClick: () => void;
    isSubmitting: boolean;
}) => {
    const { customerStatus, loading } = useCustomerStatus({
        customerId: props.values.customerID,
        distributionChannelId: props.values.distributionChannelID,
        distributorId: props.values.distributorID,
        regionId: props.values.regionID,
    });

    return (
        <Tooltip title={customerStatus === "locked" ? t("addUserForm.customerLocked.tooltip") : undefined}>
            <div>
                <LoadingButton
                    onClick={props.onClick}
                    fullWidth
                    variant="contained"
                    disabled={props.isSubmitting || customerStatus === "locked"}
                    loading={loading}
                >
                    {props.children}
                </LoadingButton>
            </div>
        </Tooltip>
    );
};

const getPages = ({
    onSelectCustomer,
    distributors,
    currentCustomer,
}: {
    onSelectCustomer: (customer?: BaseCustomer) => void;
    distributors: Distributor[] | null;
    currentCustomer?: BaseCustomer;
}): MultiFormStepConfig[] => [
    {
        name: "vko",
        title: t("addUserForm.title"),
        step: t("addUserForm.step.vko.description"),
        validationSchema: Yup.object().shape({
            distributorID: Yup.string().required(t("validationError.disbributor")),
        }),
        content: <VkoSelection distributors={distributors} />,
        autoOverflow: true,
    },
    {
        name: "customer",
        title: t("addUserForm.title"),
        step: t("addUserForm.step.customerNumber.description"),
        validationSchema: Yup.object().shape({
            customerID: Yup.string().required(t("validationError.customerNumber")),
        }),
        content: (
            <CustomerNumberSelection onSelectCustomer={onSelectCustomer} initialSearch={currentCustomer?.externalId} />
        ),
        autoOverflow: true,
    },
    {
        name: "details",
        title: t("addUserForm.title"),
        step: t("addUserForm.step.userData.description"),
        validationSchema: userValidationSchema(),
        content: <UserDetailsFields />,
        autoOverflow: true,
    },
];

const initialValues: FormikPostUserRequestsPayload = {
    corporatePositionID: "",
    customerID: "",
    distributionChannelID: "",
    distributorID: "",
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    regionID: "",
    salutation: "",
    accountType: "",
    roleId: "",
};

type AddUserFormProps = {
    onSubmit?: () => void | Promise<void>;
    onClose: () => void;
};

export const createUser = async (
    model: PostUserRequestsPayloadV2,
    inviteUser?: boolean,
    invitationType?: PostCreateUserInvitePayloadInvitationTypeEnum,
    onUserCreated?: () => void,
) => {
    try {
        generalStore.setIsLoading(true);
        const user = await API.postUserRequest(model as PostUserRequestsPayloadV2);

        if (inviteUser) {
            try {
                await API.postInviteUsers([user.id], undefined, invitationType);
                generalStore.setSuccessMessage(t("success.addUserAndInvite"));
            } catch (error) {
                generalStore.setError(t("error.addUser.invite"), error);
            } finally {
                await onUserCreated?.();
                generalStore.setIsLoading(false);
                return;
            }
        }

        generalStore.setSuccessMessage(t("success.addUser"));
    } catch (error) {
        if (isErrorOfType(error, "EMAIL_MISSING_FOR_PERMISSION_SET")) {
            inviteUser
                ? generalStore.setError(t("error.addUser.addAndInvite.emailMissing"), error)
                : generalStore.setError(t("error.addUser.add.emailMissing"), error);
        } else if (inviteUser) {
            generalStore.setError(t("error.addUser.addAndInvite"), error);
        } else {
            generalStore.setError(t("error.addUser"), error);
        }
    } finally {
        await onUserCreated?.();
        generalStore.setIsLoading(false);
    }
};

export const AddUserForm = observer(({ onClose, onSubmit }: AddUserFormProps) => {
    const [activeStep, setActiveStep] = React.useState(0);
    const [inviteUser, setInviteUser] = React.useState(false);
    const [selectedCustomer, setSelectedCustomer] = React.useState<BaseCustomer | undefined>();
    const { distributors } = useDistributors();

    const handleClickNext = (
        model: FormikPostUserRequestsPayload,
        helpers: FormikHelpers<FormikPostUserRequestsPayload>,
    ) => {
        helpers.setTouched({});
        helpers.setSubmitting(false);

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleClickBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const [userPayload, setUserPayload] = React.useState<PostUserRequestsPayloadV2>();

    const inviteDialog = useInvitationTypeSelection({ hideBackdrop: true, onClose, userPayload });

    const handleSubmit = async (model: FormikPostUserRequestsPayload) => {
        const userData = cloneDeep(model);
        userData.phone = normalizePhoneNumber(userData.phone);

        if (!isValidPhoneNumber(userData.phone)) {
            // For removal -> don't send empty string
            userData.phone = undefined;
        }

        if (!userData.email) {
            // For removal -> don't send empty string
            userData.email = undefined;
        }

        // If both email and phone are filled out, open invite type selection dialog
        const inviteSelection = inviteUser && userData.email && userData.phone;
        if (inviteSelection) {
            setUserPayload(userData as PostUserRequestsPayloadV2);
            inviteDialog.open();
            return;
        }

        // Select invitation type based on whether email or phone was provided
        let invitationType: PostCreateUserInvitePayloadInvitationTypeEnum | undefined;
        if (inviteUser) {
            invitationType = userData.email ? "email" : "sms";
        }

        // Now create and invite
        await createUser(userData as PostUserRequestsPayloadV2, inviteUser, invitationType, async () => {
            await onSubmit?.();
            onClose();
        });
    };

    const handleSelectCustomer = React.useCallback((customer?: BaseCustomer) => {
        setSelectedCustomer(customer);
    }, []);

    const handleSubmitRequestUserManagementDialog = async () => {
        if (selectedCustomer) {
            try {
                generalStore.setIsLoading(true);
                await API.postCustomerRequestOptIn(selectedCustomer.id);
                generalStore.setSuccessMessage(t("common.success"));
                onClose();
            } catch (error) {
                generalStore.setError(t("error.customerOptIn"), error);
            } finally {
                generalStore.setIsLoading(false);
            }
        }
    };

    const userManagementDialog = useConfirmationDialog({
        ...getUserDialogConfiguration("requestUserManagement", { user: generalStore.user }),
        onSubmit: handleSubmitRequestUserManagementDialog,
    });

    const pages = getPages({ onSelectCustomer: handleSelectCustomer, distributors, currentCustomer: selectedCustomer });
    const { title, content, validationSchema, autoOverflow } = pages[activeStep];

    initialValues.distributorID = distributors && distributors.length > 0 ? distributors[0].id : "";

    const getOptionalStepTitle = (index: number, values?: FormikPostUserRequestsPayload) => {
        if (index === 0) {
            return distributors?.find((distributor) => distributor.id === values?.distributorID)?.shortName;
        }
        if (index === 1) {
            return selectedCustomer?.externalId;
        }
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                onSubmit={activeStep < pages.length - 1 ? handleClickNext : handleSubmit}
                validationSchema={validationSchema}
                validateOnChange
            >
                {({ errors, isSubmitting, submitForm, values, touched }) => (
                    <Form
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            overflow: "hidden",
                            height: "100%",
                        }}
                        noValidate
                    >
                        <div>
                            <DialogHeader
                                title={title}
                                onClose={onClose}
                                onBack={
                                    activeStep > 0
                                        ? () => {
                                              handleClickBack();
                                          }
                                        : undefined
                                }
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                flexGrow: 1,
                                overflow: autoOverflow ? "auto" : "hidden",
                                marginTop: 16,
                                paddingTop: 8,
                            }}
                        >
                            <Stepper activeStep={activeStep} orientation="vertical">
                                {pages.map((page, index) => (
                                    <Step key={page.step}>
                                        <StepLabel
                                            optional={
                                                index < activeStep ? (
                                                    <p style={{ color: Colors.GREY_500, fontSize: 14 }}>
                                                        {getOptionalStepTitle(index, values)}
                                                    </p>
                                                ) : null
                                            }
                                        >
                                            {page.step}
                                        </StepLabel>
                                        <StepContent>{content}</StepContent>
                                    </Step>
                                ))}
                            </Stepper>
                        </div>
                        <div>
                            {selectedCustomer && !selectedCustomer.optIn && (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 8,
                                        marginBottom: 32,
                                        marginTop: 4,
                                    }}
                                >
                                    <p className="body2" style={{ fontSize: 14 }}>
                                        {t("addUserForm.customer.requestUserManagement.title")}
                                    </p>
                                    <LinkButton
                                        color="primary"
                                        type="button"
                                        onClick={(event) => {
                                            event?.preventDefault();
                                            userManagementDialog.open();
                                        }}
                                        style={{ fontSize: 14 }}
                                    >
                                        {t("button.requestUserManagement")}
                                    </LinkButton>
                                    <p className="body2" style={{ fontSize: 14 }}>
                                        {selectedCustomer.optInLastRequestedAt
                                            ? t("common.lastRequested", {
                                                  date: getFormattedDate(selectedCustomer.optInLastRequestedAt),
                                              })
                                            : t("common.neverRequested")}
                                    </p>
                                </div>
                            )}
                            {errors.customerID && activeStep === 1 && touched.customerID && (
                                <FieldError>{errors.customerID}</FieldError>
                            )}
                            {errors.distributorID && <FieldError>{errors.distributorID}</FieldError>}
                            {activeStep === pages.length - 1 && (
                                <>
                                    <Button
                                        onClick={() => {
                                            setInviteUser(false);
                                            submitForm();
                                        }}
                                        fullWidth
                                        style={{ marginBottom: 16 }}
                                        disabled={isSubmitting}
                                    >
                                        {t("addUserForm.button.save")}
                                    </Button>
                                    <InviteButton
                                        values={values}
                                        onClick={() => {
                                            setInviteUser(true);
                                            submitForm();
                                        }}
                                        isSubmitting={isSubmitting}
                                    >
                                        {t("addUserForm.button.saveAndInvite")}
                                    </InviteButton>
                                </>
                            )}
                            {activeStep < pages.length - 1 && (
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    disabled={isSubmitting || (selectedCustomer && !selectedCustomer.optIn)}
                                >
                                    {t("button.next")}
                                </Button>
                            )}
                        </div>
                        {userManagementDialog.component}
                    </Form>
                )}
            </Formik>
            {inviteDialog.component}
        </>
    );
});
