import { useState } from "react";
import { t } from "../i18n/util";
import { API } from "../network/API";
import { BaseRole, RoleGrantDetails, UserRequest } from "../network/APITypes";
import { generalStore } from "../stores/GeneralStore";
import { useConfirmationDialog } from "./useConfirmationDialog";

const filterDeletedRoles = (roles: BaseRole[], deletedRoles: RoleGrantDetails[]) => {
    return roles
        .filter((role) => {
            const isDeletedRole = deletedRoles.find((deleteRole) => deleteRole.id === role.id);
            return !isDeletedRole;
        })
        .map((role) => role.id);
};

export function useEditPermissionsDeleteRoleDialog({
    onSubmit,
    linkedUsers,
}: { onSubmit?: (permissionIdentifier: string) => void | Promise<void>; linkedUsers?: Array<UserRequest> } = {}) {
    const [deleteRoles, setDeleteRoles] = useState<RoleGrantDetails[]>([]);
    const [user, setUser] = useState<UserRequest>();
    const [permissionIdentifier, setPermissionIdentifier] = useState<string>("");

    const dialog = useConfirmationDialog({
        title: t(
            deleteRoles.length === 1
                ? "editPermissions.deleteRole.title.singular"
                : "editPermissions.deleteRole.title.plural",
        ),
        message: t(
            deleteRoles.length === 1
                ? "editPermissions.deleteRole.message.singular"
                : "editPermissions.deleteRole.message.plural",
            {
                roles: deleteRoles
                    .map((role) => {
                        if (role.grantedFor === user?.customerID) {
                            return `"${role.name}"`;
                        } else {
                            const customerNumber = linkedUsers?.find(
                                (user) => user.customerID === role.grantedFor,
                            )?.externalCustomerID;
                            return `"${role.name} (${t("common.customerNumber.short")}: ${customerNumber ?? "-"})"`;
                        }
                    })
                    .join(", "),
            },
        ),
        submitLabel: t("button.remove"),
        cancelLabel: t("common.no"),
        onSubmit: async () => {
            if (!user) {
                return;
            }

            try {
                generalStore.setIsLoading(true);

                await API.putUserRoles(user.id, {
                    roleIdentifiers: filterDeletedRoles(user.roles, deleteRoles),
                });

                // Remove roles granted for linked users
                if (linkedUsers) {
                    for (const role of deleteRoles) {
                        if (role.grantedFor !== user.customerID) {
                            const linkedUser = linkedUsers.find((user) => user.customerID === role.grantedFor);

                            if (linkedUser) {
                                await API.putUserRoles(linkedUser.id, {
                                    roleIdentifiers: filterDeletedRoles(linkedUser.roles, deleteRoles),
                                });
                            }
                        }
                    }
                }

                await onSubmit?.(permissionIdentifier);
                generalStore.setSuccessMessage(t("common.success"));
            } catch (error) {
                generalStore.setError(t("error.deleteRole"), error);
            } finally {
                generalStore.setIsLoading(false);
            }
        },
    });

    return {
        ...dialog,
        open(user: UserRequest, permissionIdentifier: string, roles: RoleGrantDetails[]) {
            if (roles.length > 0) {
                setUser(user);
                setPermissionIdentifier(permissionIdentifier);
                setDeleteRoles(roles);
                dialog.open();
            }
        },
    };
}
